<template>
  <tx-dialog
    v-model="visible" :title="t('whiteboard.dynamic.properties.title')" width="600px"
    show-ok-cancel @click="doCancel()" @ok="doConfirm()"
  >
    <div class="w-full h-full flex flex-col max-h-[70vh] overflow-hidden">
      <tx-select
        v-if="hasTemplateLayout" v-model="templateLayoutLabel" :data="templateLayouts" value-prop="label" class="mb-6"
        display-prop="label" filterable clearable :placeholder="t('whiteboard.dynamic.properties.layout')"
        @change="onTemplateLayoutChange()"
      />
      <tx-tabs v-show="showAdvancedSettings" v-model="activeTab" class="pb-3" :tabs="[{ key: 'template', label: 'whiteboard.dynamic.properties.template', visible: true }, { key: 'filter', label: 'whiteboard.dynamic.properties.criteria', visible: true }]" />
      <!-- Template tab -->
      <div v-show="showAdvancedSettings && activeTab === 'template'" class="overflow-hidden flex flex-col">
        <tx-select
          v-model="templateId" :data="templatesList" value-prop="id" class="mb-6"
          display-prop="name" filterable clearable :placeholder="t('whiteboard.dynamic.properties.template')"
          @change="onTemplateChange()"
        >
          <template #prefix>
            <font-awesome-icon icon="fa-light fa-file-alt" class="w-4" />
          </template>
        </tx-select>

        <div class="mb-6 text-md text-center">
          {{ t('whiteboard.dynamic.properties.templateOptions') }}
          <tx-button v-if="isAdmin" faicon="fa-light fa-copy" type="icon" @click="copyTemplateOptions" />
        </div>
        <div class="overflow-auto">
          <div v-for="opt in templateOptions" :key="opt.id" class="mb-6">
            <tx-select
              v-if="opt.type === 'groupBy'" v-model="templateOptionValues[opt.id]" multiple-values
              :label="opt.name" :data="groupByOptions" value-prop="articleProperty" display-prop="displayLabel"
              filterable clearable :placeholder="opt.name" :multiple-limit="opt.max"
            />
            <tx-select
              v-else-if="opt.type === 'attributes'" v-model="templateOptionValues[opt.id]" multiple-values
              :label="opt.name" :data="attributes" value-prop="articleProperty" display-prop="displayLabel"
              filterable clearable :placeholder="opt.name" :multiple-limit="opt.max"
            />
            <tx-select
              v-else-if="opt.type === 'state'" v-model="templateOptionValues[opt.id]"
              :label="opt.name" :data="states" value-prop="StateId" display-prop="StateName"
              filterable clearable :placeholder="opt.name" :multiple-limit="opt.max"
            />
            <tx-input
              v-else-if="opt.type === 'number'" v-model="templateOptionValues[opt.id]" type="number" :placeholder="opt.name" :label="opt.name"
              clearable
            />
            <tx-select
              v-else-if="opt.type === 'text' && opt.list?.length" v-model="templateOptionValues[opt.id]"
              :data="opt.list as any[]" :label="opt.name" filterable clearable :placeholder="opt.name"
            />
            <tx-input-color-picker
              v-else-if="opt.type === 'color'" v-model="templateOptionValues[opt.id]" :label="opt.name"
              clearable
            />
            <tx-input
              v-else v-model="templateOptionValues[opt.id]" type="text" :placeholder="opt.name" :label="opt.name"
              clearable
            />
          </div>
        </div>
      </div>
      <!-- Filter tab -->
      <div v-show="showAdvancedSettings && activeTab === 'filter'">
        <articles-filter ref="articlesFilterRef" :criteria="criteria" simple class="max-h-[70vh]" />
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, nextTick, onMounted, ref } from 'vue'
import { isEqual } from 'lodash-es'
import type { DynamicTemplateOptionValue, IDynamicTemplate } from '../services/templates/dynamicGrid/dynamicGridTemplateFactory'
import { DynamicGridTemplateFactory } from '../services/templates/dynamicGrid/dynamicGridTemplateFactory'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxInputColorPicker from '@/shared/components/TxInputColorPicker.vue'
import { useUserStore } from '@/store/userData'
import ArticlesFilter from '@/shared/components/ArticlesFilter.vue'
import { FilterCriteria } from '@/models/filterCriteria'
import { privileges } from '@/models/constants'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', templateId: string, options: Record<string, DynamicTemplateOptionValue>, criteria: FilterCriteria[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const visible = ref(false)
const loading = ref(false)
const templateId = ref('')
const templateOptionValues = ref<{ [config: string]: any }>({})
const criteria = ref<FilterCriteria[]>([])
const articlesFilterRef = ref<InstanceType<typeof ArticlesFilter>>()
const errorMsg = ref('')
const activeTab = ref('template')
const templateLayoutLabel = ref('')
let templatesList: IDynamicTemplate[] = []

onMounted(() => {
  templatesList = DynamicGridTemplateFactory.getInstance().getTemplateList()
})

const isAdmin = computed(() => {
  if (userStore.userProfile) {
    return userStore.currentUserType === '90' || userStore.userProfile.isValidPrivilege(privileges.admin.accessAdminModule)
  }
  return userStore.currentUserType === '90'
})

const states = computed(() => {
  return userStore.articleStateList.filter(state => state.Status)
})

const templateLayouts = computed(() => {
  return [...userStore.activeCatalog?.Config.DynamicGridTemplateSettings || [], { templateId: '-1', label: t('whiteboard.dynamic.properties.customLayout'), filter: [], options: {} }]
})

const hasTemplateLayout = computed(() => {
  return templateLayouts.value && templateLayouts.value.length > 1
})

const showAdvancedSettings = computed(() => {
  return !hasTemplateLayout.value || templateLayoutLabel.value === t('whiteboard.dynamic.properties.customLayout')
})

function showDialog(opt: { templateId: string, options: Record<string, DynamicTemplateOptionValue> }, filters: FilterCriteria[]) {
  loading.value = false
  visible.value = true
  templateId.value = opt.templateId
  templateOptionValues.value = { ...opt.options }
  criteria.value = filters
  // Find any template settings that matches the tmeplateId, options and filters
  const filtersWithoutStatus = JSON.parse(JSON.stringify(filters.filter(f => f.attribute !== 'Status').map(p => ({ ...p }))))
  const matchingLayout = templateLayouts.value.find(t => t.templateId === opt.templateId && isEqual(t.options, opt.options) && isEqual(t.filter, filtersWithoutStatus))
  if (matchingLayout) {
    templateLayoutLabel.value = matchingLayout.label
  }
  else {
    if (Object.keys(opt.options).length > 0 || filters.length > 0) {
      templateLayoutLabel.value = t('whiteboard.dynamic.properties.customLayout')
    }
    else {
      const firstNonCustomLayout = templateLayouts.value.find(t => t.templateId !== '-1')
      if (firstNonCustomLayout) {
        templateLayoutLabel.value = firstNonCustomLayout.label
        templateId.value = firstNonCustomLayout.templateId
        resetTemplateOptions(firstNonCustomLayout.options, firstNonCustomLayout.filter)
      }
      else {
        templateLayoutLabel.value = t('whiteboard.dynamic.properties.customLayout')
      }
    }
  }
  nextTick(() => {
    articlesFilterRef.value?.resetCriteria(filters.length === 0)
  })
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function doConfirm() {
  const error: string[] = []

  if (!templateId.value || templateId.value.length <= 0) {
    error.push(t('whiteboard.dynamic.properties.errors.missingTemplate'))
  }

  criteria.value = articlesFilterRef.value?.getCriteriaResult() || []
  if (criteria.value.length <= 0) {
    error.push(t('whiteboard.dynamic.properties.errors.missingCriteria'))
  }

  if (error.length > 0) {
    errorMsg.value = error.join('\n')
    return
  }

  visible.value = false
  console.log('Confirm', templateId.value, templateOptionValues.value, criteria.value)
  emit('confirm', templateId.value, templateOptionValues.value, criteria.value)
}

async function copyTemplateOptions() {
  const type = 'text/plain'
  const obj = {
    label: '[Enter Name]',
    templateId: templateId.value,
    filter: articlesFilterRef.value?.getCriteriaResult() || [],
    options: templateOptionValues.value,
  }
  const text = JSON.stringify(obj)

  const clipboardItemData = {
    [type]: text,
  }
  const clipboardItem = new ClipboardItem(clipboardItemData)
  await navigator.clipboard.write([clipboardItem])
}

const groupByOptions = computed(() => {
  if (userStore.activeCatalog) {
    const res = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string }>
    Object.keys(userStore.activeCatalog.Config.GroupByAttributes).forEach((itm) => {
      if (itm === '_Segmentation') {
        itm = '_Segmentations'
      }
      if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(itm)) {
        res.push({ displayLabel: userStore.myAttributes[itm].DisplayName, articleProperty: itm })
      }
      else {
        console.warn(`Misconfigured GroupByAttributes: Property ${itm} not found`)
      }
    })
    return res
  }
  else {
    return []
  }
})

const attributes = computed(() => {
  if (userStore.activeCatalog) {
    const res = [] as Array<{ displayLabel: string, articleProperty: string }>
    for (const itm in userStore.myAttributes) {
      res.push({ displayLabel: userStore.myAttributes[itm].DisplayName, articleProperty: itm })
    }
    return res
  }
  else {
    return []
  }
})

const templateOptions = computed(() => {
  if (templateId.value && templateId.value.length > 0) {
    const template = templatesList.find(t => t.id === templateId.value)
    if (template) {
      return Object.entries(template.options).map(([key, value]) => {
        return { id: key, ...value }
      })
    }
  }
  return []
})

function resetTemplateOptions(options: { [key: string]: any }, filter?: any[]) {
  templateOptionValues.value = {}
  templateOptions.value.forEach((opt) => {
    if (opt.type === 'groupBy') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || []
    }
    else if (opt.type === 'attributes') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || []
    }
    else if (opt.type === 'number') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || 0
    }
    else if (opt.type === 'text') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || ''
    }
    else if (opt.type === 'state') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || 0
    }
    else if (opt.type === 'color') {
      templateOptionValues.value[opt.id] = options[opt.id] || opt.default || ''
    }
  })

  if (filter) {
    criteria.value = filter.map(f => new FilterCriteria(f))
    nextTick(() => {
      articlesFilterRef.value?.resetCriteria(filter.length === 0)
    })
  }
}

function onTemplateLayoutChange() {
  const layout = templateLayouts.value.find(t => t.label === templateLayoutLabel.value)
  if (!layout || layout.templateId === '-1') {
    return
  }
  templateId.value = layout.templateId
  resetTemplateOptions(layout?.options || {}, layout?.filter || [])
}

function onTemplateChange() {
  resetTemplateOptions({})
}

defineExpose({
  showDialog,
})
</script>
