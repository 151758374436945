import type { fabric } from 'fabric'
import { ModelFocusTemplate } from './modelFocusTemplate'
import { PivotViewTemplate } from './pivotViewTemplate'
import type MyArticle from '@/models/myArticle'

export interface IDynamicTemplateOption {
  name: string
  type: 'number' | 'text' | 'attributes' | 'groupBy' | 'state' | 'color'
  min: number
  max: number
  required: boolean
  default: string | number | string[] | null
  list?: readonly string[] | readonly number[]
}

export type DynamicTemplateOptionValue = string | number | string[] | null

export type DynamicTempalateOptionValues<T> = { [K in keyof T]: DynamicTemplateOptionValue }

export interface IDynamicTemplate {
  id: string
  name: string
  options: { [config: string]: IDynamicTemplateOption }
  minRequiredWidth: number
  minRequiredHeight: number
  build: (canvas: fabric.Canvas, myAttributes: Record<string, IMyAttribute>, options: { [config: string]: DynamicTemplateOptionValue }, showLabels: boolean, width: number, height: number, top: number, left: number, articles: MyArticle[]) => Promise<boolean>
  getTitle: () => string
  recalculate: (width: number, height: number, startTop: number, startLeft: number) => boolean
  destroy: (canvas: fabric.Canvas) => void
  hideObjects: (hidden: boolean) => void
  render: (ctx: CanvasRenderingContext2D, width: number, height: number, top: number, left: number) => void
  propagateEvent: (eventName: string, event: any) => void
}

export class DynamicGridTemplateFactory {
  private static instance: DynamicGridTemplateFactory

  constructor() {
  }

  public static getInstance(): DynamicGridTemplateFactory {
    if (!DynamicGridTemplateFactory.instance) {
      DynamicGridTemplateFactory.instance = new DynamicGridTemplateFactory()
    }

    return DynamicGridTemplateFactory.instance
  }

  public getTemplate(id: string): IDynamicTemplate {
    switch (id) {
      case 'modelFocus':
        return new ModelFocusTemplate()
      case 'pivotView':
        return new PivotViewTemplate()
      default:
        throw new Error('Template not found')
    }
  }

  public getTemplateList(): IDynamicTemplate[] {
    return [new ModelFocusTemplate(), new PivotViewTemplate()]
  }
}
