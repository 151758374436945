{
  "general": {
    "selectAll": "Select all",
    "sharedTags": "Shared tag | Shared tags",
    "myTags": "My tag | My tags",
    "filter": "Filter",
    "default": "Default",
    "groupBy": "Group by",
    "sort": "Sort",
    "row": "Row",
    "column": "Column",
    "rowDivider": "Row Divider",
    "columnDivider": "Column Divider",
    "thumbnailSize": "Thumbnail size",
    "reset": "Reset",
    "criteria": "Criteria",
    "value": "Value",
    "range": "Range",
    "cancel": "Cancel",
    "apply": "Apply",
    "confirm": "Confirm",
    "close": "Close",
    "loading": "Loading...",
    "article": "Article",
    "articles": "Articles",
    "model": "Model",
    "models": "Models",
    "startTyping": "Start typing",
    "username": "Username",
    "password": "Password",
    "fullRange": "Full Range",
    "success": "Success",
    "search": "Search",
    "emptyList": "No items available",
    "noResult": "No Result",
    "season": "Season",
    "next": "Next",
    "hi": "Hi",
    "supportEmail": "support{'@'}trasix.com",
    "contactSupportAt": "contact Trasix support at: @:general.supportEmail",
    "please": "Please",
    "clickHere": "Click here",
    "dropped": "Dropped",
    "invalidProperty": "Invalid Property",
    "tags": "Tags",
    "history": "History",
    "images": "Images",
    "resources": "Resources",
    "records": "Record | Records",
    "actions": "Action | Actions",
    "unexpectedError": "An unexpected error occurred. If you continue to receive this error, kindly @:general.contactSupportAt",
    "update": "Update",
    "updatedSuccessfully": "Updated Successfully",
    "assign": "Assign",
    "save": "Save",
    "browseOrDropFileHere": "Browse or Drag & drop file here",
    "unsupportedFile": "{fileName} format is not supported",
    "fileUploadError": "Uploading file failed",
    "allowedFileFormats": "Allowed File Formats: {fileFormats}",
    "added": "Added",
    "removed": "Removed",
    "add": "Add",
    "remove": "Remove",
    "removeConfirmation": "Are you sure you want to remove {label}?",
    "checkAll": "Check all",
    "unableToUpdate": "Unable to update",
    "articleNumber": "@:general.article Number",
    "modelNumber": "@:general.model Number",
    "articleName": "@:general.article Name",
    "modelId": "@:general.model Id",
    "modelName": "@:general.model Name",
    "blankValue": "[Blank]",
    "related": "Related",
    "alert": "Alert",
    "from": "From",
    "to": "To",
    "all": "All",
    "active": "Active",
    "inactive": "Inactive",
    "notAssorted": "Not Assorted",
    "globallyDropped": "Globally Dropped",
    "exclude": "Exclude",
    "share": "Share",
    "bold": "Bold",
    "italic": "Italic",
    "resolve": "Resolve",
    "title": "Title",
    "comment": "Comment",
    "loadingCatalogData": "Loading Catalog data...",
    "loadingCatalogDataDelay1": "We are preparing the catalog for optimal user experience...",
    "loadingCatalogDataDelay2": "We are now downloading all required data...",
    "loadingCatalogDataDelay3": "It is taking longer than usual but the process is still ongoing, please wait...",
    "loadingCatalogDataDelay4": "It is possible that the servers are not responding or you are experiencing issues with the internet connection, you can try to continue waiting or refresh the page. If the problem persists, please contact support",
    "refresh": "Refresh",
    "created": "Created",
    "typeToFilter": "Filter the list",
    "whiteboard": "Whiteboard",
    "notifications": "Notification | Notifications",
    "switchCatalog": "Switch Catalog",
    "browse": "Browse",
    "export": "Export",
    "more": "More",
    "categories": "Categories",
    "savedViews": "Saved Views",
    "create": "Create",
    "warning": "Warning",
    "delete": "Delete",
    "edit": "Edit",
    "tree": "Tree",
    "blank": "(blank)",
    "switchUser": "Switch User",
    "logout": "Logout",
    "ok": "Ok",
    "discard": "Discard",
    "placeholder": "Placeholder",
    "switchCatalogAlert": "Are you sure you want to switch the catalog ({catalogName})?",
    "back": "Back",
    "finish": "Finish",
    "fill": "Fill",
    "change": "Change",
    "present": "Present",
    "sizeScale": "Size Scale",
    "masterSizeScale": "Master Size Scale",
    "sizes": "Size | Sizes",
    "status": "Status",
    "sortOrder": "SortOrder",
    "deliveryDates": "Delivery Dates",
    "segmentations": "Segmentations",
    "seasons": "Seasons",
    "favoriteTags": "Favorite Tags",
    "primaryColor": "Primary Color",
    "clickToBrowse": "Click to browse",
    "dragAndDrop": "drag and drop",
    "browseBy": "Browse by",
    "browsingBy": "Browsing by",
    "browseByModel": "Browse by @:general.model",
    "browseByArticle": "Browse by @:general.article",
    "connecting": "CONNECTING...",
    "TYSKU": "TY SKU",
    "customSort": "Custom Sort",
    "name": "Name",
    "code": "Code",
    "articleState": "@:general.article State",
    "modelState": "@:general.model State",
    "silhouetteColor": "Silhouette Color",
    "SKUs": "SKUs",
    "getBucketsValue": "Get buckets value",
    "articleLifecycle": "@:general.article Lifecycle",
    "modelLifecycle": "@:general.model Lifecycle",
    "none": "None",
    "zero": "0",
    "clear": "Clear",
    "yes": "Yes",
    "no": "No",
    "orders": "Orders",
    "replicateQty": "Replicate Qty",
    "reopened": "Reopened",
    "details": "Details",
    "numberOfCopies": "Total no. of copies",
    "submit": "Submit",
    "assorted": "Assorted",
    "import": "Import",
    "large": "Large",
    "medium": "Medium",
    "small": "Small",
    "request": "Request",
    "addArticles": "Add @:general.articles",
    "addRequests": "Add Requests",
    "showRequests": "Show Requests",
    "enterValue": "Enter Value",
    "showAll": "Show All",
    "viewAll": "View All",
    "hideInactive": "Hide Inactive",
    "viewRecord": "View Record",
    "generate": "Generate",
    "targetsSummary": "Targets Summary",
    "summary": "Summary",
    "settings": "Settings",
    "showComments": "Show Comments",
    "showFavorites": "Show Favorites",
    "noArticleToGenerateSummary": "There are no @:general.articles available, please review your selection and try again",
    "fileList": "File List",
    "noFilesUploaded": "No files uploaded",
    "loadingMore": "Loading more...",
    "messages": {
      "updateSuccessfully": "Updated data successfully",
      "updateFailed": "Failed to update data"
    },
    "dataRefresh": "Data Refresh"
  },
  "routes": {
    "Login": "Login",
    "CatalogsList": "Catalogs",
    "OpenCatalog": "Open Catalog",
    "CatalogDetailsView": "Catalog",
    "Admin": "Admin",
    "orders": "Orders",
    "requests": "Requests"
  },
  "validations": {
    "formInvalid": "Please check form validations",
    "invalidValue": "Entered value is not valid",
    "required": "The field {property} is required.",
    "minValue": "The {property} field must have a min value of {min}",
    "maxValue": "The {property} field must have a max value of {max}",
    "minLength": "The {property} field must have a min length of {min}",
    "maxLength": "The {property} field must have a max length of {max}",
    "nonZero": "The {property} field must have value greater than Zero",
    "atleast": "aleast fill one filed",
    "pattern": "Entered value is not valid",
    "consecutiveWhiteSpaceNotAllowed": "Article Number cannot have consecutive spaces!",
    "positiveColorCode": "Code value should be a non-negative number between 1-999"
  },
  "login": {
    "haveRegistration": "Have a registration code already?",
    "registerNow": "Register now",
    "title": "Sign in",
    "welcomeTitle1": "Welcome to",
    "welcomeTitle2": "T1-Studio",
    "rememberMe": "Remember me",
    "forgotPassword": "Forgot Password?",
    "changeUsername": "Click to go back to change user",
    "loginUsersHeading": "You have multiple users linked to your account, please select the user you want to login with",
    "resetPasswordHeader": "You need to set your password in order to use the application",
    "resetPasswordSubHeader": "to set your password",
    "resetPasswordBody": "You will be redirected to set password page in {count} seconds",
    "errors": {
      "invalidCredentials": "The credentials you have entered are not valid.",
      "unexpectedError": "An unexpected error occurred while trying to login. If you continue to receive this error, kindly @:general.contactSupportAt",
      "deActivatedUser": "Your account is deactivated. If you continue to receive this error, kindly @:general.contactSupportAt",
      "userNotExistsOnT1": "@:login.errors.invalidCredentials If you continue to receive this error, kindly @:general.contactSupportAt",
      "noUserWithValidClientId": "Your username is not allowed to access this application. For further assistance, please @:general.contactSupportAt",
      "429": "You have exceeded the number of attempts per minute allowed. Please wait a few minutes before trying again.",
      "500": "@:login.errors.unexpectedError",
      "10010": "@:login.errors.invalidCredentials If you cannot remember your password, please click on the \"Forgot Password\" button.",
      "10020": "@:login.errors.userNotExistsOnT1",
      "10040": "@:login.errors.userNotExistsOnT1",
      "10150": "@:login.errors.deActivatedUser",
      "10100": "Your username is not allowed to access this environment. For further assistance, please @:general.contactSupportAt",
      "10140": "@:login.errors.noUserWithValidClientId",
      "inValidUser": "An unexpected error occurred with your account. If you continue to receive this error, kindly @:general.contactSupportAt",
      "myUsersError": "Unable to fetch your user accounts, if you continue to receive this error please @:general.contactSupportAt",
      "title": "Error",
      "invalid": "Invalid credentials ({code})",
      "invalidUsername": "Invalid username",
      "unreachable": "Unable to reach T1, please make sure you are connected to the internet",
      "session": "Your session has expired and you must login again",
      "errorDescriptionForMultipleUser": "Currently you are logged in with different user. First logout and then try again"
    }
  },
  "setPassword": {
    "title": "Set Password",
    "heading": "A secret code has been sent to your registered Email Address. Please enter the received secret code and new password and click on set password",
    "secretCode": "Secret Code",
    "errors": {
      "unexpectedError": "An unexpected error occurred while trying to set password. If you continue to receive this error, kindly @:general.contactSupportAt",
      "429": "You have exceeded the number of attempts per minute allowed. Please wait a few minutes before trying again.",
      "500": "@:setPassword.errors.unexpectedError",
      "10010": "The code you have entered is invalid, please try again."
    }
  },
  "catalogSelection": {
    "title": "Catalog selection",
    "filter": "Type to filter",
    "nCatalogs": "{n} Catalog | {n} Catalogs",
    "selectCustomerTitle": "Select customer",
    "selectCustomerDetails": "Please select the customer for whom you want to open this catalog",
    "customerDropdown": "Customer (Displaying first 100 items)",
    "openCatalog": "Open catalog",
    "useCustomerPriceGroup": "Use the price groups assigned to the customer",
    "recent": "Recently accessed catalogs"
  },
  "filter": {
    "title": "Filter",
    "criteriaPlaceholder": "Select a criteria to filter on",
    "addCriteria": "Add criteria",
    "requestArticles": "Request @:general.articles",
    "allArticles": "All @:general.articles",
    "catalogArticles": "Catalog @:general.articles",
    "newArticleRequest": "New @:general.article request",
    "carryoverArticleRequest": "Carryover @:general.article Request",
    "similarArticleRequest": "Similar @:general.article Request",
    "similarModelRequest": "Similar @:general.model Request",
    "requestStatus": "Request Status",
    "requestNumber": "Request Number",
    "sourceRequestNumber": "Source Request Number",
    "sourceArticleNumber": "Source @:general.article Number",
    "sourceModelNumber": "Source @:general.model Number",
    "requestId": "Request Id",
    "requestType": "Request Type",
    "requestReason": "Request Reason",
    "new": "New",
    "approve": "Approve",
    "reject": "Reject",
    "draft": "Draft",
    "confirm": "Confirm",
    "false": "False",
    "true": "True"
  },
  "articlesList": {
    "noArticles": "There are no @.lower:general.articles here",
    "nArticles": "{n} @:general.articles",
    "nModels": "{n} @:general.models"
  },
  "favEditor": {
    "editFavorites": "Edit Favorites",
    "newTag": "New Tag",
    "tagsLimit": "You cannot create any more tags since you have reached the limit of {n}"
  },
  "status": {
    "memory": "Memory",
    "storage": "Storage",
    "build": "Build",
    "listedGroups": "Listed groups",
    "listedArticles": "Listed @.lower:general.articles",
    "totalArticles": "Total @.lower:general.articles",
    "selectedArticles": "Selected @.lower:general.articles",
    "listedModels": "Listed @.lower:general.models",
    "totalModels": "Total @.lower:general.models",
    "selectedModels": "Selected @.lower:general.models",
    "totalOrders": "Total orders",
    "totalSlides": "Total slides",
    "totalBoards": "Total boards"
  },
  "catalog": {
    "actionBar": {
      "deselect": "Clear selection",
      "saveImages": "Save images",
      "editFav": "Edit favorites",
      "copy": "Copy",
      "copyArt": "Copy @:general.articles",
      "copyModels": "Copy @:general.models",
      "editArticles": "Edit @:general.articles",
      "carryOverArticles": "Carryover @:general.articles",
      "editPrices": "Edit Prices",
      "updateBucketAttributes": "Update Bucket Attributes",
      "approveRequests": "Approve Requests",
      "rejectRequests": "Reject Requests",
      "confirmRequests": "Confirm Requests"
    }
  },
  "notifications": {
    "dataRefreshing": "Refreshing data...",
    "dataRefreshed": "The data has been successfully refreshed",
    "dataRefreshError": "The data failed to refresh",
    "artsCopied": "@:general.article numbers copied to clipboard",
    "copyFailed": "Unable to copy to clipboard",
    "linkCopied": "Link copied",
    "noActiveObject": "No object is selected",
    "clonedSuccessfully": "Selected object(s) are cloned as image successfully",
    "privilegeError": "It seems that you do not have the privilege to execute this action. If you believe that you should, please click on the \"Request Help\" button",
    "networkError": "It seems we are having difficulties connecting to our servers, please make sure you have a stable connection to the internet and try again. If the issue persists, please click on the \"Request Help\" button",
    "modelsCopied": "@:general.model numbers copied to clipboard",
    "dataHasLinkedArticles": "Slides cannot be generated as listed data has some linked @:general.articles"
  },
  "notificationDropdown": {
    "importArticles": "Import @:general.articles",
    "generatePPT": "Generate PPT",
    "generatePdf": "Generate PDF",
    "download": "Ready. Click here to download",
    "completed": "Completed",
    "failed": "Failed",
    "processing": "Processing",
    "updateSizes": "Update Sizes",
    "assortArticles": "Assort @:general.articles",
    "updateViaImport": "Update Via Import",
    "updateViaImportModel": "Update Via Import(@:general.model)",
    "importByLicensee": "Import By Licensee",
    "setSizesByLicenseeModelNumber": "Set Sizes(Bulk) By Licensee @:general.model Number",
    "setPricesByLicenseeModelNumber": "Set Prices(Bulk) By Licensee @:general.model Number",
    "markAsUnread": "Mark As Unread",
    "markAsRead": "Mark As Read",
    "tabs": {
      "updatesToMyRequest": "Updates To My Request",
      "requestsPendingMyAction": "Requests Pending My Action",
      "others": "Others"
    },
    "editRequestBulk": "Edit Requests(Bulk)",
    "requestNotFound": "Please refresh the catalog to access the selected request",
    "readStatusFailed": "Currently unable to mark {read}. Pease try after sometime."
  },
  "notificationErrorDialog": {
    "title": "Import @:general.article Error List - Background Job Id {jobId}"
  },
  "priceGroupsLabel": {
    "wholesalePrice": "Wholesale Price",
    "retailPrice": "Retail Price",
    "outletPrice": "Outlet Price",
    "orderPrice": "Order Price"
  },
  "articleDetails": {
    "modelStats": "@:general.model Stats",
    "modelDetails": "@:general.model Details",
    "requests": "Requests",
    "modelSeasons": "@:general.model Seasons",
    "articleDetails": "@:general.article Details",
    "modelOrArticleAttributes": "@:general.model / @:general.article attributes",
    "requestAttributes": "Request attributes",
    "requestArticle": "Request @:general.article",
    "actions": {
      "edit": "Edit",
      "editSegmentations": "Edit Segmentations",
      "editRetailWindow": "Edit Retail Window",
      "editArticleColor": "Edit @:general.article Color",
      "editForecasts": "Edit Forecasts",
      "addAttachments": "Add Attachments",
      "assignResources": "Assign Resources",
      "editRelations": "Edit @:general.article/@:general.model Relations",
      "editPrices": "Edit Prices",
      "editSizes": "Edit Sizes",
      "editPeriod": "Edit Period",
      "manageArticleState": "Manage @:general.article State",
      "assignColor": "Assign Color",
      "editParentModel": "Edit Parent @:general.model",
      "overwriteColorCode": "Overwrite @:general.article Code",
      "assortArticles": "Assort @:general.articles",
      "approve": "Approve",
      "reject": "Reject",
      "confirm": "Confirm",
      "lockPrices": "Lock Prices",
      "unlockPrices": "Unlock Prices"
    },
    "alerts": {
      "minQuantityOnly": "@:general.article is allocated for minimum {minQuantity} quantities",
      "maxQuantityOnly": "@:general.article is allocated for maximum {maxQuantity} quantities",
      "minAndMaxQuantity": "@:general.article is allocated for minimum {minQuantity} and maximum {maxQuantity} quantities",
      "unavailableCRD": "Delivery date is not available",
      "orderlineWithoutSize": "Size is not available.",
      "modelnameMaxLengthError": "The @:general.model Name field must have a max length of {max}",
      "linkedCatalogFetchFailed": "Currently unable to fetch @:general.article details of catalog {linkedCatalogList}. Pease try after sometime."
    }
  },
  "auditTrail": {
    "segmentations": "Segmentations",
    "prices": "Prices",
    "sizes": "Sizes",
    "PlanningValue": "Planning Value",
    "Status": "Status",
    "requestAttributes": "Request Attributes",
    "requestState": "Request State",
    "state": "State",
    "comment": "Comment",
    "reason": "Reason"
  },
  "activeOrDeactiveArticle": {
    "activate": "Activate",
    "deactivate": "Deactivate",
    "activateArticleConfirmation": "Are you sure you want to activate {articles}?",
    "deactivateArticleConfirmation": "Are you sure you want to deactivate {articles}?",
    "dropReason": "Drop Reason",
    "activateSuccessfully": "@:general.articles activated successfully",
    "deactivateSuccessfully": "@:general.articles deactivated successfully",
    "activateRequestSuccessfully": "Request @:general.articles activated successfully",
    "deactivateRequestSuccessfully": "Request @:general.articles deactivated successfully",
    "updateRequestFailed": "Due to some technical issue unable to update request @.lower:general.articles",
    "activateOrDeactivateAlert": "Are you sure you want to {action} the ({number}) request(s)?"
  },
  "lockOrUnlockArticle": {
    "lockPrices": "Lock Prices",
    "unlockPrices": "Unlock Prices",
    "lockArticleConfirmation": "Are you sure you want to lock prices for {articles}?",
    "unlockArticleConfirmation": "Are you sure you want to unlock prices for {articles}?",
    "lockSuccessfully": "@:general.articles prices locked successfully",
    "unlockSuccessfully": "@:general.articles prices unlocked successfully"
  },
  "createArticle": {
    "title": "Please Enter value for @:general.model attributes",
    "description": "Please Enter value for @:general.article attributes",
    "createModelInfo": "Creating @:general.model will be much faster if you copy form an existing @:general.model",
    "review": "Added @:general.articles Preview",
    "newRecord": "Add New Record",
    "action": "Please Pick what @.lower:general.article you'd like to add",
    "suggestions": "Apply Same Attributes for all @:general.articles",
    "messages": {
      "success": "All @.lower:general.articles created Successfully",
      "failedArticle": "Following @.lower:general.articles are not created due to some technical issue",
      "createArticlesFailed": "Due to some technical issue unable to create @.lower:general.articles",
      "createRequestFailed": "Due to some technical issue unable to create request",
      "carryoverArticleAlreadyExistInCatalog": "Following Carryover @:general.article(s) {carryoverArticleFailedDueToAlreadyArticleExists} already exists in catalog. Please refresh the catalog to see the latest data",
      "carryoverArticleCreationFailed": "Due to some technical issue unable to create following Carryover @:general.article(s) {carryoverFailedArticleNumberList}",
      "carryoverArticleAlreadyExistInCatalogAndFewCarryoverFailed": "Due to some technical issue unable to create following Carryover @:general.article(s) {carryoverFailedArticleNumberList} and for following Carryover @:general.article(s) {carryoverArticleFailedDueToAlreadyArticleExists} already exists in catalog. Please refresh the catalog to see the latest data",
      "jobSuccess": "Your import @.lower:general.articles job request has successfully submitted. Please check the status of job in the notifications"
    }
  },
  "carryOver": {
    "header": "Carryover @:general.article"
  },
  "copyModel": {
    "modelFormheader": "Copy @:general.model",
    "periodHeader": "Please provide quantities to create @.lower:general.article(s) for each period"
  },
  "updateArticle": {
    "title": "Edit",
    "seasonlessAttributeWarning": "This attribute change will impact this @.lower:general.model / @.lower:general.article in all seasons",
    "seasonlessWarningWhileSave": "This change will impact this @.lower:general.model / @.lower:general.article in all seasons. Are you sure you want to continue?",
    "errorMessage": "Error Message",
    "failedArticle": "Following @.lower:general.articles are not updated due to following issue",
    "updateSuccessfully": "@:general.articles updated successfully",
    "technicalIssue": "Technical issue",
    "stateRequiredAttributes": "Required Attributes are {attributeList}"
  },
  "editSegmentations": {
    "title": "Edit Segmentations",
    "assorted": "Assorted",
    "planned": "Planned",
    "updatedSuccessfully": "@:general.article segmentations updated successfully",
    "failedUpdateSegmentations": "Following @.lower:general.articles ({articles}) segmentations are not updated"
  },
  "updateArticleRetailWindow": {
    "title": "Edit Retail Window",
    "retailIntroMonth": "Retail Intro Month",
    "retailExitMonth": "Retail Exit Month",
    "retailWindow": "Retail Window",
    "saveSuccessfully": "Saved retail window successfully",
    "failedSaveRetailWindows": "Following @.lower:general.articles ({articles}) retail windows are not updated",
    "assignedRetailWindowIsNotValid": "Assigned retail window ({intro} - {exit}) is not valid anymore",
    "period": "Period",
    "shipmentStartDate": "Shipment Start Date",
    "shipmentEndDate": "Shipment End Date"
  },
  "updateArticleForecast": {
    "title": "Edit Forecasts",
    "forecastFor": "Forecast For",
    "SQ": "S Qty",
    "BQ": "B Qty",
    "forecastHasInvalidQuantity": "Forecast has invalid quantity",
    "updatedSuccessfully": "@:general.article forecasts updated successfully"
  },
  "assignFile": {
    "imageOrResource": "Would you like to assign this image as an @.lower:general.article asset or support resource?",
    "image": "@:general.article Asset",
    "resource": "Support Resource",
    "confirmation": "Assign image to: {articleNumber}?",
    "selectImageKey": "Select Image Key"
  },
  "assignArticleResource": {
    "title": "Assign Resources",
    "subTitle": "Select whether you want to:",
    "assignExistingResource": "Link an existing Resources",
    "addNewResource": "Add a new Resource"
  },
  "assignResourceForm": {
    "title": "Assign Resource",
    "selectResources": "Select the resources you would like to link",
    "resourceAssignedSuccessfully": "All the selected @:general.article(s) are linked with the selected Resource(s) successfully",
    "resourceAssignedFailed": "Failed to link selected @:general.article(s) with the some of the selected Resource(s). Following are the resources for which unable to link: {resources}"
  },
  "editSizes": {
    "title": "Select size scale (Displaying 100 first items)",
    "updatedSuccessfully": "Sizes Updated Successfully",
    "failedUpdateSizeScales": "Following @.lower:general.models ({models}) size scales are not updated",
    "jobSuccess": "Your update sizes job request has successfully submitted. Please check the status of job in the notifications",
    "updatedSizesProgressTitle": "Updating Sizes",
    "backgroundJobMessage": "This request for updating sizes will be submitted as a background job",
    "noCommonSizeScalesAvailable": "There is no common master size scale available for these records",
    "masterSizeScaleObsolete": "Master Size Scale and all its Size Scales are obsolete"
  },
  "editPrices": {
    "updatedSuccessfully": "Prices Updated Successfully",
    "changeReason": "@:general.article is Locked, Please provide reason for update",
    "skipChangeApproval": "Skip change Approval",
    "priceExternalUrlInfo": "This catalog is locked. To make any changes to the Prices, please go to: ",
    "lockedConfirmationMessage": "Some of the selected @:general.article(s) are locked and request of the change sent for approval and others are updated successfully"
  },
  "editParentModel": {
    "parentModelNumberAutocomplete": "Parent @:general.model Number (Displaying first 200 items, please start typing to display more...",
    "updatedSuccessfully": "Parent @:general.model Updated Successfully"
  },
  "assignColor": {
    "title": "Assign Color",
    "header": "Please select a primary color you want to assign to the @.lower:general.article",
    "colorAssignSuccessfully": "Color assigned successfully"
  },
  "overwriteColorCode": {
    "title": "Overwrite @:general.article Code",
    "invalidPattern": "Color Code should be a between 1 to 999",
    "colorCode": "Color Code",
    "header": "Please select a color code you want to assign to the @.lower:general.article"
  },
  "manageArticleState": {
    "requirementFailed": "Can not update selected @.lower:general.article(s) state",
    "eachArticleRequiredAttribute": "{articleNumber} might not have value for required attribute(s) {attributeList}",
    "updatedSuccessfully": "@:general.article updated successfully",
    "selectRequiredAttributes": "Please provide a value for the required attributes",
    "requiredAttributesErrorMessage": "might not have value for required attribute(s)"
  },
  "assortArticles": {
    "title": "Assort @:general.articles",
    "articlesAssortedSuccessfully": "@:general.articles assorted successfully.",
    "assortArticleConfirmation": "Are you sure you want to assort {articles}?",
    "backgroundJobMessage": "This request for assort @.lower:general.articles will be submitted as a background job.",
    "assortArticlesProgressTitle": "Assorting @:general.articles",
    "failedAssortArticles": "Following @.lower:general.articles ({articles}) are not assorted.",
    "jobSuccess": "Your assort @.lower:general.articles request has successfully submitted. Please check the status of job in the notifications"
  },
  "createResourceForm": {
    "title": "Create Resource",
    "resourceType": "Resource Type",
    "resourceName": "Resource Name",
    "resourceImage": "Resource Image",
    "resourceIcon": "Resource Icon",
    "category": "Category",
    "sortOrder": "Sort Order",
    "cascading": "Cascading",
    "description": "Description",
    "selectResources": "Select the resources you would like to link",
    "resourceAddedSuccessfully": "Resource added successfully"
  },
  "manageObjectRelation": {
    "title": "Edit @:general.article/@:general.model Relations",
    "addRelation": "Add Relation",
    "removeRelation": "Remove Relation",
    "related": "Related",
    "number": "Number",
    "name": "Name",
    "actions": "Actions",
    "request": "Request",
    "removeRelationConfirmation": "Are you sure you want to remove relation of {label}?",
    "relationshipType": "RelationShip Type",
    "selectRelationshipType": "Select Relation Type",
    "selectArticle": "Select @:general.article",
    "addSuccessfully": "Relation created successfully",
    "deleteSuccessfully": "Relation removed successfully",
    "deleteFailed": "Failed to remove Relation",
    "addFailed": "Failed to add Relation"
  },
  "stock": {
    "stockPerSize": "Stocks per size",
    "totalStocks": "Total Stocks"
  },
  "whiteboard": {
    "dynamic": {
      "overflow": "Not all @:general.articles are visible!",
      "hideInactive": "Hide Inactive @:general.articles",
      "showInactive": "Show Inactive @:general.articles",
      "properties": {
        "title": "Dynamic Grid properties",
        "layout": "Pre-defined Layout",
        "template": "Template",
        "templateOptions": "Template Options",
        "criteria": "Filter Criteria",
        "customLayout": "Custom Layout",
        "errors": {
          "missingTemplate": "You must select a template to use",
          "missingTemplateOptions": "You must specify the template options",
          "missingCriteria": "you must specific at least one criteria to filter by"
        }
      }
    },
    "list": {
      "title": "Whiteboards",
      "recent": "Recently accessed whiteboards",
      "all": "All whiteboards",
      "my": "My whiteboards",
      "other": "Other whiteboards",
      "item": {
        "name": "Name",
        "owner": "Owner",
        "ownerEmail": "Owner Email",
        "createdDate": "Created Date",
        "updatedDate": "Updated Date",
        "actions": "Actions"
      },
      "actions": {
        "addFolder": "Add Folder",
        "addBoard": "Add Board",
        "duplicate": "Duplicate",
        "edit": "Edit",
        "delete": "Delete"
      }
    },
    "titlebar": {
      "viewing": "You are currently viewing:"
    },
    "toolbars": {
      "objects": {
        "title": "Tool bar",
        "actions": "Actions"
      },
      "actions": {
        "select": "Select",
        "text": "Text",
        "sticky": "Sticky",
        "shape": "Shape",
        "comment": "Comment",
        "draw": "Draw",
        "frame": "Frame",
        "upload": "Upload",
        "generate": "Generate",
        "dynamic": "Dynamic Grid",
        "line": "Line"
      }
    },
    "sidebar": {
      "openSidebar": "Open sidebar",
      "closeSidebar": "Close sidebar",
      "frames": "Frame | Frames"
    },
    "discussions": {
      "title": "Comments",
      "showComments": "Show {remainingCommentsLength} more comments",
      "hideComments": "Hide Comments",
      "allComments": "All Comments",
      "mentions": "Mentions",
      "showResolved": " Show Resolved"
    },
    "uploadImage": {
      "title": "Upload Images",
      "updatedProgressTitle": "uploading Image"
    },
    "inviteUsers": "Invite users",
    "manageDialog": {
      "newTitle": "New Whiteboard",
      "editTitle": "Edit Whiteboard",
      "duplicateTitle": "Duplicate Whiteboard",
      "folder": "Folder",
      "name": "Name",
      "activate": "Activate",
      "activateAndNew": "Activate & New",
      "success": "Whiteboard saved successfully",
      "error": "Unable to save whiteboard, if the error persists, please contact support",
      "alreadyExistError": "A whiteboard with same name exist, would like reactivate it or overwrite with new whiteboard?"
    },
    "deleteWhiteboards": {
      "whiteboardWarning": "This action will permanently delete the whiteboard. Do you want to continue?",
      "folderWarning": "Are you sure you want to delete this folder and all whiteboards contained within it?"
    }
  },
  "merch": {
    "label": "Merch board",
    "mySlides": "Slides",
    "sharedSlides": "Shared",
    "addArticles": "Add @:general.articles",
    "enterArticleNumber": "Enter @:general.article number",
    "addSlide": "Add Slide",
    "addFolder": "Add Folder",
    "duplicate": "Duplicate",
    "refresh": "Refresh",
    "slideProperties": "View Slide Properties",
    "editMode": "Edit Mode",
    "slideSizes": {
      "widescreen": "Widescreen",
      "A3": "A3",
      "A4": "A4"
    },
    "slideListAction": {
      "editFolder": "Edit Folder Name",
      "deleteFolder": "Delete Folder",
      "editSlide": "Edit Slide",
      "viewSlideProperties": "View Slide Properties",
      "duplicateSlide": "Duplicate Slide",
      "deleteSlide": "Delete Slide",
      "sharing": "Sharing",
      "exportAsTemplate": "Export as Template",
      "summary": "Slides Summary",
      "exportSlides": "Export Slides",
      "exportSlide": "Export Slide",
      "importSlides": "Import Slides"
    },
    "toolbars": {
      "actions": {
        "save": "Save",
        "insert": "Insert",
        "select": "Select",
        "text": "Text",
        "box": "Box",
        "triangle": "Triangle",
        "circle": "Circle",
        "line": "Line",
        "image": "Image",
        "sticky": "Sticky",
        "shape": "Shape",
        "comment": "Comment",
        "draw": "Draw",
        "frame": "Frame",
        "upload": "Upload",
        "generate": "Generate",
        "align": "Align",
        "alignLeft": "Left",
        "alignCenter": "Center",
        "alignRight": "Right",
        "alignTop": "Top",
        "alignMiddle": "Middle",
        "alignBottom": "Bottom",
        "distributeH": "Distribute Horizontally",
        "distributeV": "Distribute Vertically",
        "acrossSlide": "Across the slide",
        "undo": "Undo",
        "redo": "Redo",
        "paste": "Paste",
        "share": "Share",
        "find": "Find",
        "manageTemplate": "Manage Template",
        "showFavorites": "Show Favorites",
        "hideFavorites": "Hide Favorites",
        "modify": "Modify"
      }
    },
    "messages": {
      "notAllowedToLinkedCatalogArticle": "It is not allowed to add linked catalog @:general.article",
      "deleteSlideSuccess": "Slide deleted successfully",
      "slidesSaved": "Slides saved successfully",
      "noActiveSlide": "Please select a slide before performing the action",
      "exportedSuccessfully": "Slide exported successfully",
      "slideTemplateSizeMismatch": {
        "body": "The template you have selected is meant to be used on a {0} slide. The layout might be incorrect.",
        "title": "Slide Size Mismatch"
      },
      "slidesImportSuccess": "Slides imported successfully",
      "slidesImportError": "Slides failed to import",
      "slidesExportSuccess": "Slide(s) exported successfully",
      "slidesExportError": "Slides failed to export",
      "allSlidesDuplicated": "All the slides duplicated successfully",
      "someSlidesDuplicated": "Unable to fetch data for few slides and few are duplicated successfully"
    },
    "errors": {
      "saveFailed": "Unable to save the slides.",
      "slideContentInvalid": "Unable to open slide, slide content is either corrupted or invalid.",
      "invalidArticle": "Invalid @:general.article number: {art}",
      "invalidArticles": "one or more @:general.articles are not valid and have not been added",
      "addArticlesFailed": "Unexpected error!, could not add one or more @:general.articles",
      "invalidImage": "Unable to load image, please make sure it is a valid image file!",
      "invalidSlideName": "The name you provided for the slide is invalid, please try again",
      "duplicateSlideName": "A slide with that name already exists, please try a different name",
      "invalidFolder": "The name you provided for the folder is invalid",
      "duplicateFolderName": "A folder with that name already exists, please try a different name",
      "duplicateFailed": "Failed to duplicate any of the selected slide",
      "unableToRefresh": "Slides cannot be updated now. Try again later",
      "failedReadingSlideObjects": "Failed to load slide.\n If this error persists, please contact support.",
      "invalidSlidecontent": "Unable to open slide {slideName}, slide content is either corrupted or invalid.",
      "templateDownloadFailed": {
        "body": "Unable to download the slide template, please make sure you are connected to the internet.\nIf this error persists, please contact support.",
        "title": "Template Error"
      },
      "createSlideFailed": {
        "title": "Slide creation failed!",
        "body": "Unable to create the slide"
      },
      "failedInitMerch": {
        "title": "Failed initializing merch board",
        "body": "Please contact support if you are not able to access your merch board"
      },
      "failedDuplicateSharedSlide": {
        "title": "Failed to duplicate slides",
        "body": "Unable to duplicate shared slides.\nIf this error persists, please contact support."
      },
      "invalidArticlesAndRequests": "Invalid @:general.articles : {invalidArticles}"
    },
    "dialog": {
      "dirtyCanvas": {
        "title": "Unsaved Changes",
        "body": "There are some unsaved changes, would you like to save them?"
      },
      "addSlide": {
        "title": "Add Slide",
        "fields": {
          "folder": "Folder",
          "slideName": "Slide Name",
          "template": "Slide Template",
          "imageSize": "Image Size",
          "slideSize": "Slide Size",
          "addARIndicator": "Add AR Indicator",
          "addGHLIndicator": "Add GHL Indicator",
          "addBAndTIndicator": "Add B&T Indicator"
        }
      },
      "duplicateSlide": {
        "title": "Duplicate Slide",
        "fields": {
          "slideName": "Enter the name for the new slide"
        }
      },
      "sharedSlides": {
        "shareWarning": "This action will save your slides while sharing, click OK to continue",
        "title": "Share slide(s)",
        "selectMerchBoardSlides": "Select the slides you would like to share",
        "sharedSlidesWithUserSuccessfully": "Selected slides shared successfully",
        "sharedSlidesWithUserFailed": "An unexpected error occurred while sharing slide(s). If you continue to receive this error, kindly contact Trasix support",
        "selectUsers": "select the user(s) for sharing slides",
        "selectFolder": "Select the folders(s) for sharing slides"
      },
      "editSlide": {
        "title": "Slide Properties",
        "fields": {
          "folder": "Folder",
          "slideName": "Slide Name",
          "template": "Slide Template",
          "imageSize": "Image Size",
          "slideSize": "Slide Size",
          "addARIndicator": "Add AR Indicator",
          "addGHLIndicator": "Add GHL Indicator",
          "addBAndTIndicator": "Add B&T Indicator",
          "backgroundColor": "Background Color",
          "owner": "Owner",
          "ownerUsername": "Owner Username",
          "createdOn": "Created On",
          "updatedOn": "Updated On",
          "sharedWith": "Shared with"
        }
      },
      "deleteSlides": {
        "slideWarning": "This action will permanently delete the slide. Do you want to continue?",
        "folderWarning": "Are you sure you want to delete this folder and all slides contained within it?"
      },
      "noArticlesInTemplate": {
        "title": "Slide",
        "body": "You cannot have @:general.articles Images or @:general.articles Details in a template. Please remove these objects and try again"
      },
      "editFolder": {
        "title": "Edit Folder",
        "fields": {
          "enterNewName": "Enter the new name for this folder"
        },
        "validations": {
          "folderNameRequired": "Folder Name is required.",
          "duplicateFolderName": "A folder with that name already exists, please try a different name",
          "minLength": "Folder Name field must have a min length of 1",
          "maxLength": "Folder Name field must have a max length of 250"
        }
      },
      "modify": {
        "title": "Modify Objects",
        "selectMerchBoardSlides": "Select the slides you would like to modify(Maximum {checkedNodesLimit} slides can be selected)",
        "objectTypes": "Select Object Type",
        "objectType": {
          "ArticleDetails": "@:general.article Details",
          "ArticleImage": "@:general.article Image",
          "Circle": "Circle",
          "Line": "Line",
          "Rectangle": "Rectangle",
          "Triangle": "Triangle",
          "Image": "Image",
          "Textbox": "Textbox",
          "Group": "Group"
        },
        "objectProperties": {
          "imageType": "Image Type",
          "scale": "Scale",
          "lock": "Lock",
          "addArticleDetails": "Add @:general.article Details",
          "textColor": "Fill Color",
          "color": "Background Color",
          "font": "Font",
          "fontSize": "Font Size",
          "showLabels": "Show Labels",
          "attributes": "Attributes",
          "textAlign": "Text Align",
          "fontStyle": "Font Style",
          "border": "Border"
        },
        "saveWarning": "Selected slides contains un-saved changes, to continue click on save",
        "success": "Object properties updated successfully for selected slides",
        "failed": "For some selected slides No object found and some unable to load",
        "failedLoadingSlideObjects": "Unable to load selected slides objects",
        "partialUpdate": "Object properties updated partially for some of selected slides",
        "unsavedSlidesAlert": "Selected slides contains un-saved changes, to continue click on save"
      },
      "findAndReplace": {
        "title": "Find And Replace",
        "findWhat": "Find What",
        "replaceWith": "Replace With",
        "find": "Find",
        "findNext": "Find Next",
        "replace": "Replace",
        "replaceAll": "Replace All",
        "selectSlides": "Select the Slides to Find and Replace",
        "reachedEnd": "That was the last match.",
        "replacedText": "Text replaced successfully.",
        "replacedAll": "Text is replaced in all the slides successfully.",
        "selectMerchBoardSlides": "Select the slides to find(Maximum {checkedNodesLimit} slides can be selected)"
      }
    }
  },
  "manageFolderDialog": {
    "newTitle": "Add Folder",
    "editTitle": "Edit Folder",
    "body": "Enter the name of the new folder",
    "saveFolder": "Save Folder",
    "fields": {
      "parentFolder": "Parent folder",
      "folderName": "Folder Name"
    },
    "validations": {
      "duplicateFolderName": "A folder with that name already exists, please try a different name"
    }
  },
  "generateDialog": {
    "title": "Generate {action}",
    "generate": "Generate",
    "limitWarningForArticle": "The number of @:general.articles to be generated exceeds the maximum supported limit {articlesLimit}, remaining @:general.articles are ignored",
    "limitWarningForModel": "The number of @:general.models to be generated exceeds the maximum supported limit ie. {articlesLimit}, remaining @:general.models are ignored",
    "invalidNumberErrorArticle": "Some  @:general.articles are skipped either because they do not exist or inactive",
    "invalidNumberErrorModel": "Some  @:general.models are skipped either because they do not exist or inactive",
    "invalidOrNotExistInCurrentSeasonNumberErrorArticle": "Some @:general.articles provided are skipped either because they do not exist in the current season or inactive",
    "invalidOrNotExistInCurrentSeasonNumberErrorModel": "Some @:general.models provided are skipped either because they do not exist in the current season or inactive",
    "steps": {
      "template": {
        "title": "Template",
        "desc": "Please select the template you would like to use and the source from where the @.lower:general.articles will be loaded",
        "externalSourceDescription": "Please select the template you would like to use",
        "source": "@:general.articles Source",
        "sourceFull": "Full Catalog",
        "sourceFav": "Favorites",
        "sourceExcel": "Excel File",
        "sourceList": "List of @:general.articles",
        "sourceModelList": "List of @:general.models",
        "sourceSaveViews": "Saved Views"
      },
      "articles": {
        "title": "@:general.articles",
        "descFile": "Select the Excel file that contains the @.lower:general.articles you would like to use as a source for generating the {action}",
        "descList": "Provide a list of @.lower:general.articles that you would like to use as a source for generating the {action}. Each @.lower:general.article number should be on its own line",
        "descModelList": "Provide a list of @.lower:general.models that you would like to use as a source for generating the {action}. Each @.lower:general.model number should be on its own line",
        "descFav": "Select the Favorite tag(s) assigned to the @.lower:general.articles you would like to use as a source for generating the {action}",
        "savedView": "Select saved views that contains the @.lower:general.articles you would like to use as a source for generating the {action}",
        "noFile": "(No file selected)",
        "fileError": "Unable to load selected file!",
        "filterByTag": "Favorite Tags"
      },
      "mapping": {
        "title": "Map Columns",
        "desc": "Map the columns in the Excel file to the fields required by this template. Please make sure you map all fields that are highlighted with an asterix (*)",
        "sheet": "Sheet",
        "articleNumber": "@:general.article Number",
        "articlePrice": "@:general.article Price",
        "groupBy": "Group By",
        "fileName": "File Name"
      },
      "options": {
        "title": "Options",
        "desc": "The selected template allows you to change some options that will impact the generated {action}",
        "groupBy": "Group by",
        "titleOnEachFrame": "Show Title on each frame",
        "attributesToDisplay": "@:general.article attributes to display",
        "sortAttributes": "Sort @:general.articles by",
        "fitToOneFrame": "Fit to one frame",
        "fitToOnePage": "Fit to one page",
        "titleOnEachSlide": "Show title on each slide",
        "imageSize": "Image Size",
        "imageType": "Image Type",
        "frameSize": "Frame Size",
        "includeInactiveArticles": "Include inactive @:general.articles",
        "addARIndicator": "Add AR Indicator",
        "addGHLIndicator": "Add GHL Indicator",
        "addBAndTIndicator": "Add B&T Indicator",
        "xAxisAttributes": "Column Label",
        "yAxisAttributes": "Row Label",
        "frameTitle": "Frame Title",
        "groupByModel": "Group by @:general.model",
        "showSkuCount": "Show sku count",
        "axisLabelFontSize": "Axis Label Font Size",
        "numberOfColumns": "Number of Columns",
        "sortOrder": "Sort Order",
        "selectRowOrColumn": "Select either row label or column label",
        "slideSize": "Slide Size",
        "oneSlidePerStyle": "One slide per @:general.model",
        "sortByExcelSequence": "Sort by excel sequence",
        "sortByListSequence": "Sort by List Sequence",
        "groupByPeriod": "Group by Period",
        "oneModelPerPage": "One @:general.model per page",
        "includeAllVRAngles": "Include all VR Images",
        "slideTitle": "Slide Title",
        "numberOfArticlesOnEachFrame": "Number Of Articles On Each Frame"
      },
      "finish": {
        "title": "Finish",
        "desc": "We are now ready to generate the {action}. Please review the summary below then hit the \"Finish\" button to proceed..."
      },
      "messages": {
        "success": "{numberOf} {action} are generated.",
        "failed": "Unable to generate since there is no valid @:general.article"
      }
    }
  },
  "shareUserDialog": {
    "title": "Share User(s) / User Group(s)",
    "placeholder": "Select User(s) / User Group(s)",
    "nSelectedUsers": "{n} User Selected | {n} Users Selected",
    "addAll": "Add All",
    "removeAll": "Remove All",
    "shareSuccessfully": "Whiteboard shared successfully",
    "filter": "Type to filter",
    "viewOnly": "View Only",
    "edit": "Edit",
    "viewAndComment": "View And Comment"
  },
  "savedViewDialog": {
    "title": "Saved Views",
    "myViews": "My Views",
    "predefinedViews": "Predefined Views",
    "name": "Name",
    "saveAsCatalogWide": "Save As Catalog Wide",
    "selectFolder": "Select Folder",
    "newFolder": "New Folder (Folder will be created under selected folder)",
    "editFolder": "Edit Folder",
    "editSavedView": "Edit Saved View",
    "deleteSavedViewConfirmation": "Are you sure you want to delete saved view having name {name}?",
    "deleteFolderSavedViewConfirmation": "This action will delete all the saved views under this folder",
    "createSuccessfully": "Saved View/Folder is created successfully",
    "deleteSuccessfully": "Saved View/Folder is deleted successfully",
    "editSuccessfully": "Saved View/Folder is edited successfully",
    "validations": {
      "savedViewNameExist": "Name already exists",
      "columnOrRowNotSelected": "row divider or column divider must be selected"
    }
  },
  "findAndReplaceDialog": {
    "title": "Find And Replace",
    "findWhat": "Find What",
    "replaceWith": "Replace With",
    "find": "Find",
    "findNext": "Find Next",
    "replace": "Replace",
    "replaceAll": "Replace All"
  },
  "generateFrameDialog": {
    "title": "Generate Frames",
    "generate": "Generate",
    "steps": {
      "template": {
        "title": "Template",
        "desc": "Please select the template you would like to use and the source from where the @.lower:general.articles will be loaded",
        "source": "@:general.articles Source",
        "sourceFull": "Full Catalog",
        "sourceFav": "Favorites",
        "sourceExcel": "Excel File",
        "sourceList": "List of @:general.articles",
        "sourceModelList": "List of @:general.models",
        "sourceSaveViews": "Saved Views"
      },
      "articles": {
        "title": "@:general.articles",
        "descFile": "Select the Excel file that contains the @.lower:general.articles you would like to use as a source for generating the frames",
        "descList": "Provide a list of @.lower:general.articles that you would like to use as a source for generating the frames. Each @.lower:general.article number should be on its own line",
        "descModelList": "Provide a list of @.lower:general.models that you would like to use as a source for generating the frames. Each @.lower:general.model number should be on its own line",
        "descFav": "Select the Favorite tag(s) assigned to the @.lower:general.articles you would like to use as a source for generating the frames",
        "savedView": "Select saved views that contains the @.lower:general.articles you would like to use as a source for generating the frames",
        "noFile": "(No file selected)",
        "fileError": "Unable to load selected file!",
        "filterByTag": "Favorite Tags"
      },
      "mapping": {
        "title": "Map Columns",
        "desc": "Map the columns in the Excel file to the fields required by this template. Please make sure you map all fields that are highlighted with an asterix (*)",
        "sheet": "Sheet",
        "articleNumber": "@:general.article Number",
        "articlePrice": "@:general.article Price",
        "groupBy": "Group By",
        "fileName": "File Name"
      },
      "options": {
        "title": "Options",
        "desc": "The selected template allows you to change some options that will impact the generated frames",
        "groupBy": "Group by",
        "titleOnEachFrame": "Show Title on each frame",
        "attributesToDisplay": "@:general.article attributes to display",
        "sortAttributes": "Sort @:general.articles by",
        "fitToOneFrame": "Fit to one frame",
        "imageSize": "Image Size",
        "imageType": "Image Type",
        "frameSize": "Frame Size",
        "includeInactiveArticles": "Include inactive @:general.articles",
        "addARIndicator": "Add AR Indicator",
        "addGHLIndicator": "Add GHL Indicator",
        "addBAndTIndicator": "Add B&T Indicator",
        "xAxisAttributes": "Column Label",
        "yAxisAttributes": "Row Label",
        "frameTitle": "Frame Title",
        "groupByModel": "Group by @:general.model",
        "showSkuCount": "Show sku count",
        "axisLabelFontSize": "Axis Label Font Size",
        "numberOfColumns": "Number of Columns",
        "sortOrder": "Sort Order",
        "selectRowOrColumn": "Select either row label or column label",
        "mainGroup": "Main Group",
        "subGroup": "Sub Group",
        "subSubGroup": "Sub Sub Group",
        "attributesToDisplayForCarryoverArticles": "Attributes to display For Carryover @:general.articles",
        "attributesToDisplayForNewArticles": "Attributes to display for New @:general.articles",
        "displayAttributesOnArticleImage": "Attributes to display when no image assigned"
      },
      "finish": {
        "title": "Finish",
        "desc": "We are now ready to generate the frames. Please review the summary below then hit the \"Finish\" button to proceed..."
      }
    }
  },
  "saveImageDialog": {
    "title": "Save Images",
    "selectImageTypes": "Select the image type(s)",
    "downloadReourceImages": "Download article's resource images"
  },
  "dataTable": {
    "columnChooser": {
      "title": "Column chooser",
      "available": "Available Columns",
      "selected": "Selected Columns",
      "addAll": "Add All",
      "removeAll": "Remove All"
    },
    "xOfYSelected": "{x} of {y} Selected",
    "xFiltered": "{x} Filtered",
    "advanceFilter": {
      "filterBy": "Filter By {displayLabel}",
      "filterBlank": "Filter Blank",
      "filterExclude": "Exclude",
      "advancedFilter": "Advanced Filter",
      "clearFilter": "Clear Filter"
    },
    "dateRangeFilter": {
      "filterBlank": "Filter Blank",
      "selectDateRange": "Select Date Range",
      "startDate": "Start Date",
      "endDate": "End Date"
    },
    "export": {
      "title": "Export Data",
      "desc": "Please select what data you would like to export",
      "allData": "All Data",
      "filteredData": "Filtered Data",
      "selectedRecords": "Selected Records",
      "allColumns": "All Columns",
      "visibleColumns": "Visible Columns"
    },
    "fields": {
      "createdDate": "Created Date",
      "updatedDate": "Updated Date"
    }
  },
  "admin": {
    "articles": {
      "fields": {
        "articleId": "@:general.article Id",
        "articleNumber": "@:general.article Number",
        "modelId": "@:general.model Id",
        "modelName": "@:general.model Name",
        "modelNumber": "@:general.model Number",
        "MasterModelId": "Master @:general.model Number",
        "parentModelNumber": "Parent @:general.model Number",
        "parentModelName": "Parent @:general.model Name",
        "licenseeModelNumber": "Licensee @:general.model Number",
        "colorGroup": "Color Group",
        "colorName": "Primary Color",
        "colorCode": "Color Code",
        "hexCode": "Hex Code",
        "RAID": "Replacement Id",
        "PRAID": "Parent Replacement Id",
        "RAN": "Replacement Number",
        "PRAN": "Parent Replacement Number",
        "status": "Status",
        "dropReason": "Drop Reason",
        "locked": "Locked",
        "parentStatus": "Parent Status",
        "articleSource": "@:general.article Source",
        "blockImage": "BlockImage",
        "parentPeriod": "Parent Period",
        "period": "Period",
        "stateId": "@:general.article State Id",
        "stateName": "@:general.article State",
        "seasonalSequence": "Seasonal Sequence",
        "articleLifecycle": "@:general.article Lifecycle",
        "modelLifecycle": "@:general.model Lifecycle",
        "masterSizeScale": "Master Size Scale",
        "coloredUpBy": "Colored Up By",
        "coloredUpDate": "Colored Up Date",
        "primaryColor": "Primary Color",
        "sourceRequestId": "Source Request Id",
        "sourceRequestNumber": "Source Request Number",
        "sortOrder": "Sort Order",
        "createdDate": "Created Date",
        "updatedDate": "Updated Date",
        "modelStateId": "@:general.model State Id",
        "modelStateName": "@:general.model State",
        "retailIntroMonth": "Retail Intro Month",
        "retailExitMonth": "Retail Exit Month",
        "shipmentStartDate": "Shipment Start Date",
        "shipmentEndDate": "Shipment End Date",
        "isOutOfShippingRange": "Out Of Retail Window",
        "articleSourceSeason": "Season @:general.article Carried Over From",
        "modelSourceSeason": "Season @:general.model Carried Over From",
        "activationDate": "@:general.article Reactivation Date",
        "dropDate": "@:general.article Drop Date",
        "modelCreatedDate": "@:general.model Created Date",
        "modelDropDate": "@:general.model Drop Date",
        "modelActivationDate": "@:general.model Reactivation Date",
        "allowRetailWindowUpdateOnChild": "Allow Retail Window Update On Child",
        "allowRetailWindowUpdate": "Allow Retail Window Update",
        "createdBy": "Created By",
        "updatedBy": "Updated By",
        "pSizeScale": "Parent Size Scale",
        "sizeScaleId": "Size Scale Id",
        "pSizeScaleId": "Parent Size Scale Id",
        "sizeScale": "Size Scale",
        "masterSizeScaleId": "Master Size Scale Id",
        "pShipmentStartDate": "Parent Shipment Start Date",
        "pShipmentEndDate": "Parent Shipment End Date",
        "pRetailIntroMonth": "Parent Retail Intro Month",
        "pRetailExitMonth": "Parent Retail Exit Month",
        "pPeriod": "Parent Period",
        "pShipmentWindowRangeId": "Parent Retail Window Id",
        "stateRank": "State Rank",
        "modelStateRank": "Model State Rank",
        "isAssigned": "Is Assigned"
      },
      "actions": {
        "importArticles": "Import @:general.articles",
        "updateViaImport": "Update Via Import",
        "updateViaImportModel": "Update Via Import(@:general.model)",
        "importByLicensee": "Import By Licensee",
        "setSizesByLicenseeModelNumber": "Set Sizes(Bulk) By Licensee @:general.model Number",
        "setPricesByLicenseeModelNumber": "Set Prices(Bulk) By Licensee @:general.model Number"
      },
      "messages": {
        "jobSuccess": "Your import @:general.articles job request has successfully submitted. Please check the status of job in the notifications",
        "updateViaImportJobSuccess": "Your Update Via Import job request has successfully submitted. Please check the status of job in the notifications",
        "updateViaImportModelJobSuccess": "Your Update Via Import(@:general.model) job request has successfully submitted. Please check the status of job in the notifications",
        "importByLicenseeJobSuccess": "Your Import By Licensee job request has successfully submitted. Please check the status of job in the notifications",
        "setSizesByLicenseeModelNumberJobSuccess": "Your Set Sizes(Bulk) By Licensee @:general.model Number job request has successfully submitted. Please check the status of job in the notifications",
        "setPricesByLicenseeModelNumberJobSuccess": "Your Set Prices(Bulk) By Licensee @:general.model Number job request has successfully submitted. Please check the status of job in the notifications",
        "editRequestBulk": "Your Edit Requests(Bulk) job request has successfully submitted. Please check the status of job in the notifications"
      },
      "dialog": {
        "noEditablePriceGroups": {
          "title": "Prices",
          "body": "Unable to update prices because all price groups are read only!"
        },
        "unAssortedArticleDetailsMessage": {
          "title": "Articles",
          "body": "Please assign the @:general.article to the catalog to view its details"
        }
      }
    },
    "articleDetails": {
      "tabs": {
        "article": "@:general.article",
        "deliveryDates": "Delivery Dates",
        "resources": "Resources",
        "images": "Images",
        "sizes": "Sizes",
        "prices": "Prices",
        "segmentation": "Segmentation",
        "history": "History",
        "related": "Related"
      }
    },
    "requests": {
      "messages": {
        "editRequestBulk": "Your Edit Requests(Bulk) job request has successfully submitted. Please check the status of job in the notifications"
      }
    },
    "main": {
      "seasonlessRequests": {
        "details": {
          "tabs": {
            "request": "Request"
          }
        }
      }
    }
  },
  "fields": {
    "parentModelName": "Parent @:general.model Name",
    "parentModelNumber": "Parent @:general.model Number",
    "sourceModelName": "Source @:general.model Name",
    "sourceModelNumber": "Source @:general.model Number",
    "parentModelType": "Parent @:general.model Type",
    "colorGroup": "Color Group",
    "coloredUpBy": "Colored Up By",
    "coloredUpDate": "Colored Up Date",
    "createdDate": "Created Date",
    "updatedDate": "Updated Date",
    "locked": "Locked"
  },
  "modelCreate": {
    "title": {
      "addNew": "Add New",
      "newModel": "Add new @:general.model",
      "copyModel": "Copy @:general.model",
      "childModel": "Create child @.lower:general.model",
      "similarModelRequest": "Similar @:general.model Request",
      "carryoverModel": "Add @.lower:general.articles to this @.lower:general.model",
      "carryoverModelRequest": "Request to Add @.lower:general.articles to this @.lower:general.model",
      "articleRequest": "Request to Add @.lower:general.article",
      "carryoverArticles": "Carryover @:general.articles",
      "carryoverArticleRequest": "Request to Carryover @:general.article",
      "copyArticle": "Copy @:general.articles"
    },
    "subTitle": {
      "modelForm": "Please enter value for @.lower:general.model attributes",
      "periodsForm": "Please provide quantities to create @.lower:general.articles(s) for each period",
      "articleForm": "Please enter value for @.lower:general.article attributes",
      "requestAttributesForm": "Please enter value for request attributes",
      "previewPage": "Added @.lower:general.article preview"
    },
    "allAttributesSameValue": "Apply same attribute value for all @.lower:general.articles",
    "addXArticles": "Add {count} @.lower:general.article | Add {count} @.lower:general.articles",
    "addXRequests": "Add {count} request | Add {count} requests",
    "addXArticlesRequest": "Add {count} @.lower:general.article request | Add {count} @.lower:general.articles request",
    "changeManagement": "To make any changes to this attribute please click on info icon",
    "createModelProgressTitle": "Creating @.lower:general.models",
    "copiesSubTitle": "This request for creating @.lower:general.models will be submitted as a background job",
    "copyModelNameMaxLength": "The @:general.model Name field must have a max length of {max}. When creating multiple copies, the character limit adjusts to include label 'copy 1 of [Model Number]'"
  },
  "customSort": {
    "title": "Custom Sort",
    "available": "Available Values",
    "sorted": "Sorted Values",
    "selectArticleProperty": "Select @:general.article Property"
  },
  "exportDialog": {
    "title": "Export",
    "steps": {
      "data": {
        "title": "Data",
        "desc": "Welcome to the Export Wizard. The following steps will guide you through the process of exporting any required information from this catalog. Please begin by selecting what information you would like to export, Or select one of the saved options",
        "articles": "@:general.articles",
        "whiteboardFrame": "Whiteboard Frame",
        "orderForm": "Order Form",
        "requests": "Requests",
        "merchBoard": "Merchandising Board"
      },
      "format": {
        "title": "Format",
        "desc": "Select the file format of the required output",
        "ppt": "PowerPoint",
        "pdf": "PDF",
        "excel": "Excel"
      },
      "filter": {
        "title": "Filter",
        "desc": "Select the source of the @.lower:general.articles you would like to export",
        "limitDesc": "Maximum {limit} @.lower:general.articles can be exported",
        "full": "Full Catalog",
        "fav": "My Favorite",
        "filterByTag": "Select the favorite tags you would like to export",
        "excel": "From Excel",
        "filterByExcel": "Select the source of the @.lower:general.articles you would like to export",
        "articles": "List of @:general.articles",
        "list": "List of @:general.articles",
        "modelList": "List of @:general.models",
        "filterByArticleNumber": "Provide a list of @.lower:general.article numbers separated. Each number should be on its own line",
        "models": "List of @:general.models",
        "filterByModelNumber": "Provide a list of @.lower:general.model numbers separated. Each number should be on its own line",
        "whiteboard": "Whiteboard",
        "selectWhiteboardFrame": "Select one or more frames you would like to export",
        "savedViews": "Saved Views",
        "frames": "Frames",
        "slides": "Slides",
        "slideSize": "Select slide size",
        "selectMerchBoardSlides": "Select one or more slides you would like to export"
      },
      "review": {
        "title": "Review",
        "desc": "We are now ready to export data. Please review the summary below then hit the \"Finish\" button. Please note that, depending on the size of the data, it might take a few seconds to a few minutes for your file to be generated.",
        "imageDownloadProgress": "Downloading Images"
      }
    },
    "messages": {
      "success": "Your export job requested has successfully submitted. Please check the status of job in the notifications",
      "excludedArticles": "Invalid @:general.articles",
      "excludedArticleList": "The following @:general.articles are excluded",
      "excludedModelList": "The following @:general.models are excluded",
      "excludedModels": "Invalid @:general.models",
      "validArticles": "Valid  @:general.articles",
      "validModels": "Valid @:general.models",
      "selectedFramesCount": "Selected Frames count",
      "selectedFrames": "Selected Frames",
      "selectedSlidesCount": "Selected Slides count",
      "selectedSlides": "Selected Slides",
      "allSlidesFailed": "Unable to download all the selected slides",
      "failedSomeSlides": "Unable to download following slides,{failedSlides}"
    },
    "excel": {
      "title": {
        "selectAttributes": "Select the attributes you would like to include in the output",
        "selectLocations": "Select the Locations you would like to include in the output",
        "selectColumns": "Select the Columns you would like to include in the output"
      },
      "includeImages": "Include Images",
      "includeImagesLimit": "(Maximum {limit} @.lower:general.articles can be exported)",
      "includeInactiveArticles": "Include Inactive @:general.articles",
      "includeInactiveRequests": "Include Inactive requests",
      "favoriteTagsInSeparateRow": "Favorite Tags In Separate Row"
    },
    "order": {
      "selectedLocations": "Selected Locations",
      "availableLocations": "Available Locations",
      "availableColumns": "Available Columns",
      "selectedColumns": "Selected Columns",
      "customer": "Customer",
      "location": "Location",
      "field": {
        "size": "Size",
        "deliveryDateDescription": "Delivery Date Description",
        "deliveryDate": "Delivery Date",
        "sizeIndex": "Size Index",
        "deliveryDateAvailabilityFrom": "Delivery Date Availability From",
        "deliveryDateAvailabilityTo": "Delivery Date Availability To",
        "customerRequiredDate": "Customer Required Date"
      }
    }
  },
  "exportPDFTemplate": {
    "options": {
      "oneModelPerPage": "One @:general.model per page",
      "sortByExcelSequence": "Sort by excel sequence",
      "includeInactiveArticles": "Include inactive @:general.articles"
    }
  },
  "contextMenu": {
    "updateBucketAttributes": "Update Bucket Attributes"
  },
  "orders": {
    "field": {
      "alias": "Alias",
      "catalogCode": "Catalog Code",
      "comment": "Comment",
      "createdByEmail": "Created By",
      "createdBy": "Created By Id",
      "createdDate": "Created Date",
      "currencyCode": "Currency Code",
      "customer": "Customer",
      "customerCodeName": "Customer (Code - Name)",
      "customerId": "Customer Id",
      "customerName": "Customer Name",
      "customerNumber": "Customer Number",
      "customerReference": "Customer Reference",
      "GFRQuantity": "GFR Quantity",
      "GFRValue": "GFR Value",
      "id": "Id",
      "inactiveQuantity": "Inactive Quantity",
      "location": "Location",
      "locationCode": "Location Code",
      "locationCodeName": "Location (Code - Name)",
      "locationId": "Location Id",
      "locationName": "Location Name",
      "orderContent": "Order Content",
      "orderProcessStatus": "Order Process Status",
      "orderProcessStatusId": "Order Process Status Id",
      "orderReference": "Order Reference",
      "orderType": "Order Type",
      "orderTypeId": "Order Type Id",
      "priceGroupName": "Price Group Name",
      "processStatus": "Process Status",
      "quantities": "Quantities",
      "revisionNumber": "Revision Number",
      "sellerAccountId": "Seller Account Id",
      "source": "Source",
      "sourceId": "Source Id",
      "status": "Status",
      "stockCode": "Stock Code",
      "stockDefinitionId": "Stock Definition Id",
      "stockName": "Stock Name",
      "totalCurrentValue": "Total Current Value",
      "totalInitialValue": "Total Initial Value",
      "totalCurrentQuantity": "Total Current Quantity",
      "totalPrice": "Total Price",
      "totalQuantity": "Total Quantity",
      "unitPrice": "Unit Price",
      "updatedBy": "Updated By Id",
      "updatedByEmail": "Updated By",
      "updatedDate": "Updated Date",
      "isUnSavedOrdersPresent": "Is Unsaved Orders Present"
    },
    "misc": {
      "orderReferences": "Order References"
    },
    "actions": {
      "reopen": "Reopen",
      "send": "Send Orders",
      "delete": "Delete",
      "import": "Import"
    },
    "createOrder": {
      "title": "Create Order",
      "fields": {
        "type": "What type of order would you like to create",
        "blank": "Blank Order",
        "fromFavorites": "Order From Favorites",
        "filterArticleByFavorites": "Filter Favorite @:general.articles by Tag",
        "customer": "Customer",
        "location": "Location",
        "alias": "Alias",
        "customerReference": "Customer Reference"
      }
    },
    "dirtyOrdersDialog": {
      "title": "Unsaved Orders",
      "description": "There are orders that have not been saved yet, would you like to save them?"
    },
    "reopenOrders": {
      "success": "Order(s) reopened successfully",
      "failed": "We are facing some issues reopening your order(s), please make sure you are connected to internet and try again. \n If you continue to receive this error please @:general.contactSupportAt"
    },
    "deleteOrders": {
      "promptDialogTitle": "Remove Orders",
      "promptDialogDescription": "This action will remove the order from your list, please click 'Ok' to continue",
      "success": "Order(s) removed successfully",
      "failed": "We are facing some issues removing your order(s), please make sure you are connected to internet and try again. \n If you continue to receive this error please @:general.contactSupportAt"
    },
    "sendOrder": {
      "sendMultipleOrderTitle": "Send Multiple Orders",
      "alerts": {
        "sendOrderWithZeroQuantity": "Any orderline with zero quantity will be excluded from order.",
        "invalidMOQWarning": "The quantity of the following @:general.article(s) is less than Minimum Order quantity: { MOQFailedOrderlineList }",
        "invalidArticlesMustHaveWarning": "@:general.article(s) failed with MLM criteria in the order are: { missingArticlesMustHaveList }",
        "submitOrder": "To submit the order, click OK.",
        "checkDataPackageUpdate": "Please wait while we are checking for data package update",
        "receiveOrderClosed": "System is closed for orders."
      },
      "successAlert": {
        "orderSendSuccessful": "Order {orderReference} sent successfully"
      },
      "failedAlerts": {
        "title": "Failed submitting order",
        "genericMessage": "Failed sending order {orderReference}",
        "receiveOrderClosedMessage": "System is closed for orders.",
        "noInternetMessage": "Unable to send order, please make sure you are connected to internet and try again.",
        "unexpectedErrorMessage": "We are facing some issues submitting your order, please contact customer service regarding this issue",
        "invalidArticlesMessage": "One or more orderlines are invalid as @:general.articles are invalid, please update the data and resend the Order.",
        "articleSizeDroppedMessage": "Sizes {sizeList} are dropped from the @:general.article, please remove Size Curve quantity",
        "MOQCriteriaFailedMessage": "One or more orderlines violates the minimum order quantity criteria, please update the quantity and resend the Order. | One or more orderlines violates the minimum order quantity criteria, please update the quantity and resend the Orders.",
        "MSRCriteriaFailedMessage": "One or more orderlines violates the MSR criteria, please update the quantity and resend the Order. | One or more orderlines violates the MSR criteria, please update the quantity and resend the Orders.",
        "deliveryDateCriteriaFailedMessage": "One or more orderlines violates the customer required date criteria, please update the date and resend the Order.",
        "multipleOfQuantityFailedMessage": "One or more orderlines violates the multiple of quantity criteria, please update the quantity and resend the Order. | One or more orderlines violates the multiple of quantity criteria, please update the quantity and resend the Orders.",
        "noOrderlineMessage": "There are no orderline to be sent",
        "outOfStockMessage": "Failed to submit the order! your order contains products with quantities greater than available stock. for more details click on more details"
      }
    },
    "alerts": {
      "orderCanNotBeUpdated": {
        "general": "Active order for this location can not be updated!",
        "disapproved": "This order has been rejected and cannot be edited. To make any changes to it, please contact your account manager.",
        "approved": "This order has been approved and locked. To make any changes to it, please contact your account manager.",
        "locked": "This order is locked and no changes are allowed.",
        "submitting": "This order has been sent to the server and is currently being processed, no changes are allowed until it has completed processing.",
        "confirmed": "This order has been confirmed and locked. To make any changes to it, please contact your account manager.",
        "submitted": "This order has been submitted. To make changes, please re-open it.",
        "getProcessStatusApiFailed": "We are facing some issues submitting your order, please contact customer service regarding this issue",
        "inactiveLocation": "Order can not be updated, this order belongs to a customer location that does not exist anymore.",
        "doesNotHaveOwnership": "Order can not be updated, you do not own this order.",
        "inactiveOrder": "Order can not be updated, order is inactive.",
        "inactiveLocationForSendOrders": "Order can not be sent, this order belongs to a customer location that does not exist anymore.",
        "doesNotHaveOwnershipForSendOrders": "Order can not be send, you do not have ownership for this order.",
        "inactiveOrderForSendOrders": "Order can not be send, order is inactive.",
        "orderCannotBeSend": "Failed sending order",
        "noOrderline": "There are no orderline to be sent",
        "unexpectedError": "Unexpected error! failed to send order",
        "userCancelled": "user cancelled the order",
        "getOrderApiFailed": "We are facing some issues in retrieving the order, please try again later."
      },
      "replicateMessage": "Copied Quantities: "

    },
    "importOrder": {
      "title": "Import Order",
      "mapping": {
        "sheet": "Sheet",
        "articleNumber": "@:general.article Number",
        "deliveryDateDescription": "Delivery Date Description",
        "size": "Size"
      },
      "fields": {
        "selectOption": "Select an option",
        "newOrder": "New order",
        "overwriteExistingOrders": "Overwrite existing orders"
      },
      "labels": {
        "totalValidRows": "Total valid rows ",
        "totalInvalidRows": "Total invalid rows ",
        "importValidationFailedMessage": "There are currently some invalid rows, please rectify these issues before continuing.",
        "skipInvalidRows": "Skip invalid rows",
        "getResult": "Get Result"
      },
      "validations": {
        "nonEditableOrders": {
          "title": "Unable to import orders",
          "body": "There are order(s) that can not be overwritten!"
        },
        "missingOrderReference": {
          "title": "Unable to import orders",
          "body": "Missing/Invalid order reference in imported sheet"
        },
        "invalidCustomer": "{customerNumber} at cell \"{cell}\" is not a valid customer or wrong data format",
        "invalidLocation": "{locationCode} at cell \"{cell}\" is not a valid location or does not belong to customer {customerNumber} or wrong data format",
        "invalidOrderReference": "Order reference {orderReference} at cell \"{cell}\" is not a valid order reference or does not belong to customer {customerNumber} or location {locationCode}",
        "noneEditableOrder": "Order reference {orderReference} at cell \"{cell}\" currently is in un-editable state, check order status",
        "newOrderEmptyOrderColumn": "Note: column \"{columns}\" does not contain a valid quantity and no order will be created for that",
        "overwriteOrderEmptyOrderColumn": "Note: column \"{columns}\" does not contain a valid quantity and will be ignored",
        "noOrderWithValidQuantity": "There is no order with valid quantity to be imported!",
        "doesNotHaveOwnership": "You do not own order reference {orderReference} at cell \"{cell}\" and it can not be updated",
        "importSuccessful": "Orders imported successfully",
        "orderCreatedPartially": "Failed creating order for following locations \n{locations}",
        "noOrderCreated": "Failed to import Orders",
        "articleNotSegmented": "@:general.article {articleNumber} is not available for customer {customerNumber}"
      }
    }
  },
  "orderlines": {
    "field": {
      "grandTotal": "Grand Total",
      "grandTotalActive": "Grand Total Active"
    },
    "actions": {
      "save": "Save",
      "fill": "Fill Order",
      "send": "Send",
      "reopen": "Reopen",
      "refresh": "Refresh",
      "removeOrderlines": "Remove Orderlines",
      "columnPicker": "Column Picker",
      "filterOrderlines": "Filter Orderlines"
    },
    "dirtyOrderDialog": {
      "title": "Unsaved Order",
      "description": "There are changes that have not been saved yet, would you like to save them?",
      "retry": "We are unable to save the order, please make sure you are connected to internet and try again. \n If you continue to receive this error please @:general.contactSupportAt"
    },
    "saveOrder": {
      "success": "Saved order successfully",
      "failed": "Save order failed",
      "noChangesToSave": "There are no changes to be saved"
    },
    "updateOrder": {
      "success": "Updated order successfully",
      "failed": "Update order failed"
    },
    "fillOrder": {
      "description": "Please select whether you want to fill the Order from favorites or from a list of @:general.articles",
      "tabFavorites": "Favorites",
      "tabArticlesList": "@:general.articles List",
      "favoriteTags": "Include @:general.articles Having the Following Favorite Tag(s)",
      "articleListLabel": "Provide a list of @:general.articles (one @:general.article per line)",
      "validListDescription": "Following @:general.article will be added to orderline | Following @:general.articles will be added to orderline",
      "unavailableListDescription": "Following @:general.article is not available and no orderline will be created for it | Following @:general.articles are not available and no orderline will be created for them",
      "noValidArticles": "There is no valid @:general.articles to be added"
    },
    "alerts": {
      "orderlineCanNotBeUpdated": {
        "inactiveArticle": "@:general.article is not available anymore",
        "invalidCatalogOrCustomerSegmentation": "Catalog/Customer segmentation is deactivated",
        "invalidPriceGroup": "Price-group is deactivated",
        "invalidArticleSeg": "@:general.article segmentation is deactivated",
        "allocationCriteriaField": "Allocation criteria failed",
        "invalidSegmentation": "Invalid segmentation",
        "customerUnlinked": "Unlinked Customer",
        "inactiveCatalogCRD": "Delivery date is not active for this catalog",
        "inactiveArticleCRD": "Delivery date is not assigned to @:general.article",
        "unavailableCRD": "Delivery date is not available",
        "orderlineWithoutSize": "Size is not available"
      },
      "unableToLoadArticleDetails": "Unable to open @:general.article details"
    }
  },
  "requests": {
    "createRequest": "Create Request",
    "newArticleRequest": "New @:general.article Request",
    "approveArticleRequest": "Approved @:general.article Request",
    "rejectArticleRequest": "Rejected @:general.article Request",
    "confirmArticleRequest": "Confirmed @:general.article Request",
    "carryoverArticleRequest": "Carryover @:general.article Request",
    "similarModelRequest": "Similar @:general.model Request",
    "approveRequest": "Approve Request",
    "rejectRequest": "Reject Request",
    "new": "New",
    "approve": "Approve",
    "reject": "Reject",
    "draft": "Draft",
    "confirm": "Confirm",
    "comments": "Comments",
    "similar": "Similar",
    "carryover": "Carryover",
    "similarModel": "Similar @:general.model",
    "reasons": "Reasons",
    "impactedAttribute": "Impacted Attribute",
    "currentValue": "Current Value",
    "requestedValue": "Requested Value",
    "sourceModelNumber": "Source @:general.model Number",
    "sourceArticleNumber": "Source @:general.article Number",
    "requestApprovesSuccessfully": "Requests approved successfully",
    "requestRejectedSuccessfully": "Requests rejected successfully",
    "approved": "Approved",
    "confirmed": "Confirmed",
    "rejected": "Rejected",
    "createdBy": "Created By",
    "updatedBy": "Updated By",
    "attachments": "Attachments",
    "addAttachments": "Add Attachments",
    "requestIsAlreadyApproved": "Request is already approved, please refresh your data to get the latest | Requests are already approved, please refresh your data to get the latest",
    "requestIsAlreadyRejected": "Request is already rejected, please refresh your data to get the latest | Requests are already rejected, please refresh your data to get the latest",
    "confirmEditRequestMessage": "Are you sure you want to confirm this request?",
    "confirmEditPricesRequestMessage": "Are you sure you want to confirm this request? This change will impact this @.lower:general.model / @.lower:general.article in these seasons { seasons } ",
    "editRequestDialog": {
      "currentValue": "Current Value",
      "requestedValue": "Requested Value",
      "seasonsDataMessage": "This change will impact this @.lower:general.model / @.lower:general.article in these seasons { seasons }"
    },
    "title": {
      "editAttributeRequest": "Edit Attribute Request",
      "editArticleStateRequest": "Edit State Request",
      "editSizeScaleRequest": "Edit Size Scale Request",
      "editModelNameRequest": "Edit @:general.model Name Request",
      "editPrimaryColorRequest": "Edit Primary Color Request",
      "editPricesRequest": "Edit Prices Request",
      "editStatusRequest": "Edit Status Request",
      "requestedPrices": "Requested Prices",
      "createEditRequest": "Create Edit Request",
      "approvalRejectionReason": "Approval/Rejection Reason",
      "attachments": "Attachments"
    },
    "errors": {
      "failedToFetchSeasons": "Failed to fetch seasons data"
    },
    "viewAttachments": {
      "attachmentPreview": "Attachment Preview",
      "download": "Download",
      "attachmentDeletedSuccessfully": "Attachment deleted successfully"
    }
  },
  "dataImporter": {
    "title": "Import Data",
    "skipInvalidRows": "Skip Invalid Rows",
    "file": {
      "lastUsedFile": "Last Used File",
      "newFile": "New File"
    },
    "columnMapping": {
      "heading": "Please select the columns in your sheet that match each of the following fields.",
      "subHeading": "All fields marked with a * are mandatory and must be mapped before continuing."
    },
    "filter": {
      "desc": "Would you like to import all the rows in the sheet or only the rows that match a specific criteria?",
      "importAllRows": "Import All Rows",
      "importSpecificRows": "Import Only the Rows that match the following criteria",
      "include": "Include",
      "exclude": "Exclude",
      "theRowsWhereColumn": "the Rows where column",
      "contains": "contains",
      "anyValue": "Any Value",
      "noValue": "No Value (Empty)",
      "aSpecificValue": "A Specific Value",
      "selectColumn": "Select Column"
    },
    "validations": {
      "validatingData": "Validating Data...",
      "status": "Status",
      "finished": "Finished",
      "totalValidRows": "Total Valid Rows",
      "totalInvalidRows": "Total Invalid Rows",
      "totalSkippedRows": "Total Skipped Rows",
      "importValidationFailedMessage": "There are currently some invalid rows, please rectify these issues before continuing.",
      "noRowsToSubmit": "There are no rows available to be submitted.",
      "readyToBeSubmitted": "No issues were detected in your source file. The data is ready to be submitted.",
      "dataInfoMessage": "Your data is ready to be imported, please click on the Submit button below to submit the request to the server.",
      "modelLevelDataMessage": "You are about to overwrite data for all @:general.articles(@:general.model Number)"
    },
    "labels": {
      "getResult": "Get Result"
    }
  },
  "requestsTable": {
    "fields": {
      "id": "Id",
      "requestId": "Request Id",
      "objectId": "Object Id",
      "articleNumber": "@:general.article Number",
      "status": "Status",
      "createdDate": "Created Date",
      "updatedDate": "Updated Date",
      "createdByUserName": "Created By User Name",
      "updatedByUserName": "Updated By User Name",
      "impactedAttribute": "Impacted Attribute",
      "currentValue": "Current Value",
      "requestedValue": "Requested Value",
      "requestState": "Request State",
      "reason": "Reason",
      "comment": "Comment",
      "requestType": "Request Type",
      "sourceArticleNumber": "Source @:general.article Number",
      "sourceModelNumber": "Source @:general.model Number",
      "requestNumber": "Request Number",
      "requestVersion": "Request Version",
      "stateName": "State Name",
      "impactedSeasons": "Impacted Seasons",
      "hasAttachments": "Has Attachments",
      "catalogCode": "Catalog Code",
      "catalogName": "Catalog Name",
      "sourceCatalogCode": "Source Catalog Code",
      "sourceCatalogName": "Source Catalog Name"
    },
    "actions": {
      "editRequest": "Edit Request",
      "editRequestBulk": "Edit Requests(Bulk)"
    }
  }
}
